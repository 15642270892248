import { init, BrowserClient, makeFetchTransport, defaultStackParser, defaultIntegrations } from "@sentry/browser";

init({
	dsn: "https://1dcc1908d3864993838f0b80ebf99959@o245575.ingest.sentry.io/1468670",
	sampleRate: 0.001,
	debug: isDevel,
	maxBreadcrumbs: 30,
	environment: isDevel ? "devel" : "production",
	release: "AST@" + git_rev_head2,
	beforeSend(event, hint) {
		const playerRegex = new RegExp(/videoplayer.*.\.js/g);
		const message = hint.originalException.stack;
		playerRegex.test(message) ? videoplayer.captureEvent(event) : astProject.captureEvent(event);
		return null;
	}
});

const astProject = new BrowserClient({
	dsn: "https://1dcc1908d3864993838f0b80ebf99959@o245575.ingest.sentry.io/1468670",
	beforeSend(event) {
		//console.log("ast project event: ", event);
		return event;
	},
	transport: makeFetchTransport,
	stackParser: defaultStackParser,
	integrations: defaultIntegrations
});

const videoplayer = new BrowserClient({
	dsn: "https://d44cd15916964a289a9cfe902842c4d8@o245575.ingest.sentry.io/5225837",
	beforeSend(event) {
		//console.log("video player event: ", event);
		return event;
	},
	transport: makeFetchTransport,
	stackParser: defaultStackParser,
	integrations: defaultIntegrations
});
